
import firebase from 'firebase/app'
import 'firebase/firestore'

    const firebaseConfig = {
        apiKey: "AIzaSyDp6pp0s5bwjo3S_OqclvUknTCXOZOq7Dc",
        authDomain: "fabio-santo-dev.firebaseapp.com",
        databaseURL: "https://fabio-santo-dev.firebaseio.com",
        projectId: "fabio-santo-dev",
        storageBucket: "fabio-santo-dev.appspot.com",
        messagingSenderId: "72424832579",
        appId: "1:72424832579:web:b70703a25ba97676f49ef6",
        measurementId: "G-52JFKC9XBZ"
      };

const fire = typeof window !== `undefined` ? firebase.initializeApp(firebaseConfig).firestore() : null 

export default fire;
